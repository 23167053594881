'use client';
import { Select, Option } from '@material-tailwind/react';
import { Field } from '@/types/types';
import React from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { CaretDown } from '@phosphor-icons/react';

interface Props {
  field: Field;
  labelProps?: any;
  localStorageKey?: string;
}

export default function SelectField(props: Props & FieldHookConfig<any>) {
  const selectField = props.field;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [field, meta] = useField(props);

  const handleSelectChange = (value: any) => {
    // Update Formik's state when select value changes
    setFieldValue(field.name, value);

    if (!props.localStorageKey) return;
    const storedValues = localStorage.getItem(`${props.localStorageKey}`);
    const parsedValues = storedValues ? JSON.parse(storedValues) : {};

    localStorage.setItem(
      `${props.localStorageKey}`,
      JSON.stringify({
        ...parsedValues,
        [`${props.name}`]: value,
      })
    );
  };

  const handleBlur = () => {
    // Mark the field as touched to trigger validation
    setFieldTouched(field.name, true, true);
  };

  const fieldLabel = () => {
    if (selectField.label) {
      return (
        <>
          {selectField.label}
          {selectField.required && (
            <span className='ml-0.5 inline-block text-red-500'>*</span>
          )}
        </>
      );
    }
    return '';
  };

  return (
    <>
      <Select
        variant='outlined'
        error={meta.touched && !!meta.error}
        disabled={props.disabled}
        arrow={
          <span>
            <CaretDown weight='fill' fontSize={14} />
          </span>
        }
        onBlur={handleBlur}
        onChange={(e) => handleSelectChange(e)}
        // @ts-expect-error Ignore type error
        label={fieldLabel()}
        labelProps={props.labelProps}
        value={meta.value}
        containerProps={{ className: '!h-[56px]' }}
        className={props.className}
      >
        {selectField.options &&
          selectField.options.length > 0 &&
          selectField.options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
      {meta.touched && meta.error ? (
        <div className='text-xs text-red-500'>{meta.error}</div>
      ) : null}
    </>
  );
}
