'use client';
import { Form } from '@/types/types';
import { Button } from '@/components/shared/material';
import {
  CheckboxField,
  InputField,
  RadioField,
  SelectField,
  TextareaField,
} from '@/components/shared/form';
import { Fragment } from 'react';
import { useFormikContext } from 'formik';
import InputToolTip from './InputToolTip';
import { Warning } from '@phosphor-icons/react';

export default function FormFields({
  form,
  displayOptions,
}: {
  form: Form;
  displayOptions?: {
    displayType: 'default' | 'inline';
    displayMode: 'dark' | 'light';
  };
}) {
  const { isValid, isSubmitting, dirty } = useFormikContext();

  const FormHasRequiredFields = form?.fieldGroups?.some((fieldGroup) => {
    return fieldGroup?.fields?.some((field) => field.required);
  });

  return (
    <>
      {!form?.fieldGroups && (
        <div className='rounded-md bg-yellow-100 p-5'>
          <div className='mx-auto mb-5 flex h-16 w-16 items-center justify-center rounded-full bg-yellow'>
            <Warning weight='bold' className='mx-auto text-2xl' />
          </div>
          <p className='text-center text-charcoal'>
            Oops, looks like something has gone wrong. If you need to contact
            us, see our contact details below.
          </p>
        </div>
      )}
      {displayOptions?.displayType !== 'inline' && FormHasRequiredFields && (
        <div className='text-red !mt-0'>
          <p
            className={`mb-3 text-right text-xs ${displayOptions?.displayMode === 'dark' ? 'text-[#FF7043]' : 'text-orange'} md:mb-2`}
          >
            * Required Fields
          </p>
        </div>
      )}

      <fieldset disabled={isSubmitting}>
        <div
          className={`form-fields mx-auto ${displayOptions?.displayType === 'inline' ? 'space-y-3' : 'space-y-5'}`}
        >
          {form &&
            form?.fieldGroups &&
            form?.fieldGroups.length > 0 &&
            form?.fieldGroups.map((fieldGroup, index) => (
              <div
                key={index}
                className={`flex w-full items-center gap-2 ${fieldGroup.fields && fieldGroup.fields.length && fieldGroup.fields.some((field) => field.hidden) ? '!mt-0' : ''}`}
              >
                {' '}
                {'richText' in fieldGroup && (
                  // @ts-ignore
                  <div
                    className='col-span-2 flex gap-2 text-charcoal-600 [&_a]:text-teal-600 hover:[&_a]:underline'
                    dangerouslySetInnerHTML={{
                      __html:
                        typeof fieldGroup.richText === 'string'
                          ? fieldGroup.richText
                          : String(fieldGroup.richText),
                    }}
                  ></div>
                )}
                {fieldGroup.fields &&
                  fieldGroup.fields &&
                  fieldGroup.fields.length > 0 &&
                  fieldGroup.fields.map((field, fieldIndex) => (
                    <Fragment key={fieldIndex}>
                      {field.fieldType.includes('checkbox') && (
                        <>
                          <div>
                            <div className='col-span-2 flex flex-wrap gap-2'>
                              <CheckboxField
                                disabled={isSubmitting}
                                label={field.label}
                                labelProps={{
                                  className: 'inputLabelCheckbox',
                                }}
                                containerProps={{
                                  className: 'flex-shrink-0',
                                }}
                                required={field.required}
                                name={field.name}
                                type='checkbox'
                                ripple={false}
                                className='input flex-shrink-0 p-2'
                              />
                            </div>
                            {field.description && (
                              <div
                                className='mt-5 text-sm !font-normal text-charcoal [&>a]:text-teal-600 hover:[&>a]:underline'
                                dangerouslySetInnerHTML={{
                                  __html: field.description,
                                }}
                              ></div>
                            )}
                          </div>
                        </>
                      )}
                      {field.fieldType.includes('radio') && (
                        <div>
                          <div className='flex'>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: field.label,
                              }}
                            ></div>
                            {field.required ? (
                              <span
                                className={` ${displayOptions?.displayMode === 'dark' ? 'text-[#FF7043]' : 'text-orange'}`}
                              >
                                *
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='col-span-2 flex flex-wrap md:gap-2'>
                            {field.options &&
                              field.options.length > 0 &&
                              field.options.map((option, index) => (
                                <RadioField
                                  key={index}
                                  label={option.label}
                                  labelProps={{
                                    className: '!font-normal',
                                  }}
                                  disabled={isSubmitting}
                                  ripple={false}
                                  required={field.required}
                                  value={option.value}
                                  name={field.name}
                                  type='radio'
                                  className='input p-2 font-normal'
                                />
                              ))}
                          </div>
                          {field.description && (
                            <div
                              className='mt-5 text-sm font-normal text-charcoal [&>a]:text-teal-600 hover:[&>a]:underline'
                              dangerouslySetInnerHTML={{
                                __html: field.description,
                              }}
                            ></div>
                          )}
                        </div>
                      )}
                      {field.fieldType.includes('multi_line_text') && (
                        <div className='flex-shrink-1 flex flex-grow flex-col gap-1'>
                          <TextareaField
                            disabled={isSubmitting}
                            labelProps={{ className: 'textareaLabel' }}
                            label={field.label}
                            required={field.required}
                            name={field.name}
                            containerProps={{
                              className: 'min-h-[100px]',
                            }}
                            className='input h-full min-h-[100px] w-full p-2 text-sm font-normal text-charcoal'
                            placeholder={field.placeholder ?? ''}
                            maxLength={61440}
                          />
                        </div>
                      )}

                      {(field.fieldType.startsWith('single_line_text') ||
                        field.fieldType.startsWith('number') ||
                        field.fieldType.startsWith('phone') ||
                        field.fieldType.startsWith('email')) && (
                        <div
                          className={`${field.hidden ? '!mt-0 hidden' : ''} ${field.hidden && index !== 0 ? '!-mt-5' : ''} flex-shrink-1 flex flex-grow flex-col gap-1 self-start`}
                        >
                          <InputField
                            disabled={isSubmitting}
                            type={
                              field.hidden
                                ? 'hidden'
                                : field.fieldType.startsWith('number')
                                  ? 'number'
                                  : field.fieldType.startsWith('email')
                                    ? 'email'
                                    : field.fieldType.startsWith('phone')
                                      ? 'tel'
                                      : 'text'
                            }
                            {...(field.label &&
                            displayOptions?.displayType !== 'inline'
                              ? {
                                  label: field.hidden ? undefined : field.label,
                                  labelProps: {
                                    className: 'inputLabel',
                                  },
                                }
                              : {
                                  labelProps: {
                                    className: 'hidden',
                                  },
                                })}
                            className={`input ${displayOptions?.displayMode === 'dark' ? 'dark !py-2 placeholder:!opacity-100 focus:placeholder:!opacity-0' : ''} ${
                              !field.label && 'focus:!border-t-black'
                            } `}
                            name={field.name}
                            required={field.required}
                            placeholder={field.placeholder ?? ''}
                            {...(field.fieldType.startsWith('number') && {
                              min: field.validation?.minAllowedDigits,
                              max: field.validation?.maxAllowedDigits,
                            })}
                            icon={
                              field.description ? (
                                <InputToolTip message={field.description} />
                              ) : (
                                ''
                              )
                            }
                          />
                        </div>
                      )}
                      {field.fieldType.startsWith('file') && (
                        <div className='flex-shrink-1 flex flex-grow flex-col gap-1 self-start'>
                          <InputField
                            disabled={isSubmitting}
                            type='file'
                            {...(field.label
                              ? {
                                  label: field.label,
                                }
                              : {
                                  labelProps: {
                                    className: 'hidden',
                                  },
                                })}
                            containerProps={{
                              className: '!h-[42px]',
                            }}
                            className={`input !pb-3 ${
                              !field.label && 'focus:!border-t-black'
                            }`}
                            name={field.name}
                            required={field.required}
                            placeholder={field.placeholder ?? ''}
                            {...(field.fieldType.startsWith('number') && {
                              min: field.validation?.minAllowedDigits,
                              max: field.validation?.maxAllowedDigits,
                            })}
                          />
                        </div>
                      )}
                      {field.fieldType.includes('dropdown') && (
                        <div className='flex-shrink-1 flex flex-grow flex-col gap-1 self-start'>
                          <SelectField
                            disabled={isSubmitting}
                            name={field.name}
                            required={field.required}
                            type={field.fieldType}
                            field={field}
                            className='selectInput'
                            labelProps={{
                              className:
                                '[&.text-sm]:!text-[16px] [&.text-sm]:transform [&.text-sm]:-translate-y-1 !text-[12px] !top-0.5 after:!bottom-[1px] pt-0.5 pb-1 peer-placeholder-shown:pt-0 px-3 after:!mx-[-11px]',
                            }}
                          />
                        </div>
                      )}
                    </Fragment>
                  ))}
              </div>
            ))}

          <div
            className={`${displayOptions?.displayType === 'inline' ? '' : 'pt-5'}`}
          >
            {form?.displayOptions?.submitButtonText && (
              <Button
                size='md'
                color={
                  displayOptions?.displayMode === 'dark' ? undefined : 'yellow'
                }
                className={`flex w-full items-center justify-center gap-2 !py-3 px-8 text-center !text-base ${displayOptions?.displayMode === 'dark' ? 'bg-[#EFE9DB] text-charcoal hover:bg-[#F7F4E9] hover:!text-charcoal' : ''}`}
                type='submit'
                disabled={
                  displayOptions?.displayType !== 'inline' &&
                  (!isValid || isSubmitting || !dirty)
                }
              >
                <span>{form?.displayOptions?.submitButtonText}</span>
                {isSubmitting && (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='h-4 w-4 animate-spin'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                    />
                  </svg>
                )}
              </Button>
            )}
          </div>
        </div>
      </fieldset>
    </>
  );
}
